import React from 'react';
import { graphql } from 'gatsby';
import { Router, Redirect, navigate } from '@reach/router';

import capitalizeFirstLetter from '../utils/capitalizeFirstLetter';

import Heading from '../atoms/Heading';

import HeadOverride from '../components/HeadOverride';
import ContentWrapper from '../components/ContentWrapper';
import SidebarLayout from '../components/SidebarLayout';
import Sidebar from '../components/Sidebar';
import SidebarInfo from '../components/SidebarInfo';
import SidebarActions from '../components/SidebarActions';
import BreadcrumbsBar from '../components/BreadcrumbsBar';
import Slideshow from '../components/Slideshow';
import Thumbnails from '../components/Thumbnails';
import Switch from '../components/Switch';

const Gallery = ({
  title,
  basePath,
  lookbookImages,
  contentTypeSingular,
  contentTypePlural,
  ...props
}) => {
  const handleSlideChange = slide => navigate(`${basePath}${slide}/`);

  const slideIndex = props['*'] !== '' ? props['*'] : undefined;

  const isOverview = slideIndex === 'overview';
  const slideNum =
    typeof slideIndex === 'undefined' ? 1 : parseInt(slideIndex, 10);

  return (
    <>
      <Sidebar>
        <SidebarActions>
          <Switch
            activeOption={isOverview ? 'overview' : 'slideshow'}
            options={[
              {
                id: 'slideshow',
                icon: 'slideshow',
                link: `${basePath}`,
              },
              {
                id: 'overview',
                icon: 'thumbnails',
                link: `${basePath}overview/`,
              },
            ]}
          />
        </SidebarActions>

        {!isOverview ? (
          <SidebarInfo
            data={[
              {
                label: 'Ansicht',
                content: `${capitalizeFirstLetter(
                  contentTypeSingular
                )} ${slideNum} of ${lookbookImages.length}`,
              },
            ]}
          />
        ) : (
          <SidebarInfo
            data={[
              {
                label: 'Inhalt',
                content: `${lookbookImages.length} ${contentTypePlural}`,
              },
            ]}
          />
        )}
      </Sidebar>

      <ContentWrapper withSidebar>
        <Heading headingSize={1} visuallyHidden>
          {title}
        </Heading>

        {!isOverview &&
          (slideNum >= 1 && slideNum <= lookbookImages.length ? (
            <Slideshow
              slides={lookbookImages}
              showSlide={slideNum}
              handleSlideChange={handleSlideChange}
              fullscreen
            />
          ) : (
            <Redirect to={`${basePath}`} />
          ))}

        {isOverview && (
          <Thumbnails thumbnails={lookbookImages} basePath={basePath} />
        )}
      </ContentWrapper>
    </>
  );
};

export default props => {
  const { data, pageContext } = props;

  const breadcrumbsData = data.lookbookContent.edges[0].node.fields.slugs;
  const lookbookData = data.lookbookContent.edges[0].node.data;

  const basePath = `/${pageContext.url_path}`;

  const lookbookImages = lookbookData.gallery
    .filter(item => item.image.localFile !== null)
    .map(item => {
      const { width, height } = item.image.localFile.childImageSharp.original;

      return {
        id: item.id,
        type: 'image',
        data: {
          fluid: item.image.localFile.childImageSharp.fluid,
          ratio: width / height,
          alt: item.image_description,
        },
      };
    });

  const contentTypeSingular = lookbookData.content_type_singular
    ? lookbookData.content_type_singular
    : 'image';
  const contentTypePlural = lookbookData.content_type_plural
    ? lookbookData.content_type_plural
    : 'images';

  return (
    <>
      <HeadOverride
        title={lookbookData.meta_title || lookbookData.title.text}
        description={lookbookData.meta_description}
        image={
          lookbookData.meta_image ? lookbookData.meta_image.url : undefined
        }
      />

      <BreadcrumbsBar breadcrumbs={breadcrumbsData} />

      <Router
        basepath={basePath}
        primary={false}
        component={SidebarLayout}
        withBorders
      >
        <Gallery
          path="*"
          title={lookbookData.title.text}
          basePath={basePath}
          lookbookImages={lookbookImages}
          contentTypeSingular={contentTypeSingular}
          contentTypePlural={contentTypePlural}
        />
      </Router>
    </>
  );
};

export const query = graphql`
  query LookbookQuery($url_path: String!) {
    lookbookContent: allPrismicGallery(
      filter: { fields: { url_path: { eq: $url_path } } }
    ) {
      edges {
        node {
          fields {
            slugs {
              breadcrumb
              slug
            }
          }

          data {
            title {
              text
            }
            meta_title
            meta_description
            meta_image {
              url
            }

            content_type_singular
            content_type_plural

            gallery {
              id
              image_description
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
