import React from 'react';
import styled from 'styled-components';

import Button from '../atoms/Button';

const Switch = styled.div`

`;

const Option = styled(Button)`
  && {
    border-width: 1px 1px 1px 0;
    border-radius: 0;

    ${props => props.isActive && `
      background: ${props.theme.gradient.chromeLightInverted};

      &:hover, &:focus {
        background: ${props.theme.gradient.chromeLightInverted};
      }
    `}

    &:first-child {
      border-width: 1px;
      border-radius: ${props => props.theme.radius.base} 0 0 ${props => props.theme.radius.base};
    }

    &:last-child {
      border-radius: 0 ${props => props.theme.radius.base} ${props => props.theme.radius.base} 0;
    }
  }
`;


export default ({ options, activeOption }) => (
  <Switch>
    {options.map(item => (
      <Option key={item.id} isActive={item.id === activeOption} secondary icon={item.icon} useLink link={item.link} />
    ))}
  </Switch>
);
