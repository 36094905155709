import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import Grid from './Grid';
import GridItem from './GridItem';

const ThumbnailLink = styled(Link)`
  position: relative;
  display: block;

  @media not all and (pointer: coarse) {
    ::after {
      content: '';
      display: none;
      position: absolute;
      top: -1px;
      left: -1px;
      width: 100%;
      height: 100%;
      border: 1px solid ${props => props.theme.color.borderLight};
      border-radius: 1px;
    }

    :hover,
    :focus {
      cursor: zoom-in;

      ::after {
        display: block;
      }
    }
  }
`;

export default props => {
  const { thumbnails, basePath } = props;

  return (
    <Grid>
      {thumbnails.map((thumbnail, index) => (
        <GridItem key={thumbnail.id} w={[1 / 3, 1 / 4, 1 / 5, 1 / 7]}>
          <ThumbnailLink to={`${basePath}/${index + 1}/`}>
            {thumbnail.type === 'image' && (
              <Img fluid={thumbnail.data.fluid} alt={thumbnail.data.alt} />
            )}
          </ThumbnailLink>
        </GridItem>
      ))}
    </Grid>
  );
};
